<table class="zenmode-table">
  <thead>
    <tr>
      <th>N</th>
      <th>Name</th>
      <th>Card ID</th>
      <th>Deal ID</th>
      <th>Is touched</th>
      <th>Status</th>
    </tr>
  </thead>
  <tbody>
    @for (item of viewedCards$ | async; track item; let i = $index) {
      <tr>
        <td>{{ i + 1 }}</td>
        <td>{{ item.name }}</td>
        <td>{{ item.cardId }}</td>
        <td>{{ item.dealId }}</td>
        <td>{{ item.isTouched ? 'Yes' : 'No' }}</td>
        <td>{{ item.status }}</td>
      </tr>
    }
  </tbody>
</table>
<br>
  <table class="zenmode-table">
    <thead>
      <tr>
        <th>N</th>
        <th>Name</th>
        <th>Card ID</th>
        <th>Deal ID</th>
        <th>Is touched</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      @for (item of zenmodeHistory$ | async; track item; let i = $index) {
        <tr>
          <td>{{ i + 1 }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.cardId }}</td>
          <td>{{ item.dealId }}</td>
          <td>{{ item.isTouched ? 'Yes' : 'No' }}</td>
          <td>{{ item.status }}</td>
        </tr>
      }
    </tbody>
  </table>

<table class="zenmode-table">
  <thead>
    <tr>
      <th>N</th>
      <th scope="col">Название сделки</th>
      <th scope="col">Статус</th>
      <th scope="col">Следующее задание</th>
      <th scope="col">Возвратность</th>
      <th scope="col">Время вылета</th>
      <th scope="col">Источник</th>
      <th scope="col">Время назначения</th>
      <th scope="col">Shadow task</th>
      <th scope="col">Есть новое сообщение?</th>
      <th scope="col">Последнее сообщение</th>
    </tr>
  </thead>
  <tbody>
    @for (item of combinedDealsData$ | async; track item; let i = $index) {
      <tr>
        <td><a [routerLink]="['/deals/view', item.card.id]">{{i+1}}</a></td>
        <td>{{item.card.name ? item.card.name : item.card.phone}}</td>
        <td>{{dealStatus[item.deal.status]}}</td>
        @if (item.nextTask?.type) {
          <td>{{'PAGES.DEALS.CRM_TASK_TYPES.' + item.nextTask.type | translate}}</td>
        } @else {
          <td>Не указано</td>
        }
        <td>{{item.deal.isReturnTourist ? "Возвратный": "Новый"}}</td>
        @if (item.lastSearchRequest) {
          <td>{{item.lastSearchRequest.dateFrom | date: 'dd.MM'}}</td>
        } @else {
          <td>Не указано</td>
        }
        @if (item.deal.clientSource) {
          <td>{{item.deal.clientSource.title}}</td>
        } @else {
          <td>Не указано</td>
        }
        @if (item.nextTask?.date) {
          <td>{{item.nextTask.date + ' '+ item.nextTask.time  | date: dateFormat}}</td>
        } @else {
          <td>Не указано</td>
        }
        @if (item.shadowNextTask?.date) {
          <td>
          {{item.shadowNextTask.date + ' '+ item.shadowNextTask.time  | date: dateFormat}}</td>
        } @else {
          <td>Не указано</td>
        }
        @if (item.deal?.conversationStatus; as conversationStatus) {
          <td>{{conversationStatus === 1 ? 'Да' : 'Нет'}}</td>
        } @else {
          <td>Не указано</td>
        }
        @if (item.lastWhatsappMessage) {
          <td>{{item.lastWhatsappMessage.receivedAt | date: 'dd.MM HH:mm:ss'}}</td>
        } @else {
          <td>Не указано</td>
        }
      </tr>
    }
  </tbody>
</table>


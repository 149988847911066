import { AsyncPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { map } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { CrmCardViewItem } from '@api-clients/crm-api-client/dist/models';
import { ShadowNextTasksMap } from '../../../../+state/deals/deals.interface';
import { DealsFacade } from '../../../../+state/deals/deals.facade';
import { ShadowtimeListComponent } from '../shadowtime-list/shadowtime-list.component';
import { SkippedCardsComponent } from '../skipped-cards/skipped-cards.component';
import { ViewedCardsComponent } from '../viewed-cards/viewed-cards.component';
import { ZenmodeMainListComponent } from '../zenmode-main-list/zenmode-main-list.component';

export const DealStatusByValue = {
  0: 'Новый',
  1: 'В работе',
  2: 'Пост-сейл',
};

@Component({
  selector: 'app-zenmode-list',
  templateUrl: './zenmode.component.html',
  styleUrls: ['./zenmode.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    MatTabGroup,
    MatTab,
    ZenmodeMainListComponent,
    ShadowtimeListComponent,
    ViewedCardsComponent,
    SkippedCardsComponent
],
})
export class ZenmodeComponent implements OnInit {
  constructor(private dealsFacade: DealsFacade) {}
  public dateFormat = 'dd.MM.yyyy HH:mm';
  public zenmodeList$: Observable<CrmCardViewItem[]> = this.dealsFacade.zenmodeList$;
  public dealsList$ = this.dealsFacade.dealsList$;
  public shadowNextTasksMap$: Observable<ShadowNextTasksMap> = this.dealsFacade.getShadowNextTasksMap$;
  public backwardCount$: Observable<number> = this.dealsFacade.backwardCount$;
  public untouchedCards$ = this.dealsFacade.untouchedCards$;
  public dealStatus = {
    0: 'Новый',
    1: 'В работе',
    2: 'Пост-сейл',
  };

  public combinedDealsData$ = combineLatest([this.dealsList$, this.shadowNextTasksMap$]).pipe(
    map(([dealsList, shadowMap]) => {
      return dealsList
        .filter(deal => shadowMap[deal.deal.id])
        .map(deal => ({
          ...deal,
          shadowNextTask: shadowMap[deal.deal.id],
        }));
    }),
  );
  public viewedCards$ = this.dealsFacade.viewedCards$;
  public skippedCards$ = this.dealsFacade.skippedCards$;
  ngOnInit(): void {}
}

<div class="zenmode-container">
  <div class="zenmode-header">
    @if (untouchedCards$ | async; as untouchedCards) {
      <div class="info">
        Доступны для просмотра: {{untouchedCards.length}}
      </div>
      <div class="info">
        Следующий на просмотр: {{untouchedCards[0]?.card?.name || 'нет'}}
      </div>
    }
    @if (backwardCount$ | async; as backwardCount) {
      <div class="info">
        Сдвиг пагинации назад: {{backwardCount}}
      </div>
    }


  </div>
  <mat-tab-group animationDuration="0s">
    <mat-tab label="Список Зенмода ({{ (zenmodeList$ | async)?.length }})">
      <app-zenmode-main-list></app-zenmode-main-list>
    </mat-tab>
    <mat-tab label="Список ШадоуТайма ({{ (combinedDealsData$ | async)?.length }})">
      <app-shadowtime-list></app-shadowtime-list>
    </mat-tab>
    <mat-tab label="Просмотренные карточки ({{ (viewedCards$ | async)?.length }})">
      <app-viewed-cards></app-viewed-cards>
    </mat-tab>
    <mat-tab label="Пропущенные карточки ({{ (skippedCards$ | async)?.length }})">
      <app-skipped-cards></app-skipped-cards>
    </mat-tab>
  </mat-tab-group>
</div>

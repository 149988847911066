import { AsyncPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DealsFacade } from '../../../../+state/deals/deals.facade';

@Component({
  selector: 'app-viewed-cards',
  templateUrl: './viewed-cards.component.html',
  styleUrls: ['./viewed-cards.component.scss'],
  standalone: true,
  imports: [AsyncPipe],
})
export class ViewedCardsComponent implements OnInit {
  public viewedCards$ = this.dealsFacade.viewedCards$;
  public zenmodeHistory$ = this.dealsFacade.zenmodeHistory$;
  constructor(private dealsFacade: DealsFacade) {}

  ngOnInit(): void {}
}

import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ShadowNextTasksMap } from '../../../../+state/deals/deals.interface';
import { DealsFacade } from '../../../../+state/deals/deals.facade';
import { DealStatusByValue } from '../zenmode-list/zenmode.component';

@Component({
  selector: 'app-shadowtime-list',
  templateUrl: './shadowtime-list.component.html',
  styleUrls: ['./shadowtime-list.component.scss'],
  standalone: true,
  imports: [RouterLink, AsyncPipe, DatePipe, TranslateModule],
})
export class ShadowtimeListComponent implements OnInit {
  public dateFormat = 'dd.MM.yyyy HH:mm';
  public dealsList$ = this.dealsFacade.dealsList$;
  public shadowNextTasksMap$: Observable<ShadowNextTasksMap> = this.dealsFacade.getShadowNextTasksMap$;
  public combinedDealsData$ = combineLatest([this.dealsList$, this.shadowNextTasksMap$]).pipe(
    map(([dealsList, shadowMap]) => {
      return dealsList
        .filter(deal => shadowMap[deal.deal.id])
        .map(deal => ({
          ...deal,
          shadowNextTask: shadowMap[deal.deal.id],
        }));
    }),
  );
  public dealStatus = DealStatusByValue;

  constructor(private dealsFacade: DealsFacade) {}

  ngOnInit(): void {}
}
